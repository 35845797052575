<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="borrowCodeNumber" label="借阅单号：">
                <el-input v-model.trim="searchForm.borrowCodeNumber" maxlength="50" placeholder="请输入借阅单号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="borrowUserId" label="借阅人：">
                <el-select v-model="searchForm.borrowUserId" filterable placeholder="请选择" clearable class="w100i">
                    <el-option v-for="item in userList" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowPurpose" label="借阅目的：">
                <el-select v-model="searchForm.borrowPurpose" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_purpose')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowTime" label="借阅时间：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.borrowTime"
                        type="date"
                        placeholder="请选择借阅时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="deadline" label="期限：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.deadline"
                        type="date"
                        placeholder="请选择期限" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item label-width="0px" class="f_r text_right">
                <el-button type="primary" icon="el-icon-finished" v-if="hasPermission('electronic:auditor:mySubmit')"
                           @click="mySubmit()">我的申请
                </el-button>
                <el-button type="primary" icon="el-icon-coordinate" v-if="hasPermission('electronic:auditor:audit') && searchForm.userAuditState == '1'"
                           @click="audit()">批量审核
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="searchForm.userAuditState" size="small" @tab-click="search(1)">
                <el-tab-pane :label="item.label" :name="item.value" v-for="(item, index) in tabsList" :key="index"></el-tab-pane>
            </el-tabs>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 401px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column type="selection" :reserve-selection='true' :selectable="(row) => {return row.auditState == '1' && searchForm.userAuditState == '1'}" fixed/>
                <el-table-column prop="borrowCodeNumber" label="借阅单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowUserName" label="借阅人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowPurpose" label="借阅目的" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("borrow_purpose", scope.row.borrowPurpose ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="borrowTime" label="借阅时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="deadline" label="期限" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('electronic:submitter:view')"
                                   @click="addEdit(scope.row.id, scope.$index)">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('electronic:auditor:audit') && scope.row.auditState == 1 && searchForm.userAuditState == '1'"
                                   @click="audit(scope.row)">审核
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('electronic:auditor:auditLog')"
                                   @click="auditLog(scope.row.id)">审核记录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <AuditorForm ref="auditorForm" @refreshDataList="search(0)"></AuditorForm>
        <AuditForm ref="auditForm" @refreshDataList="search(0)"></AuditForm>
        <AuditLog ref="auditLog"></AuditLog>
    </div>
</template>

<script>
    import AuditorForm from './auditorForm'
    import AuditForm from '../components/auditForm'
    import AuditLog from '../components/auditLog'

    export default {
        components: {AuditorForm, AuditForm, AuditLog},
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                searchForm: {
                    userAuditState: '1',
                    deadline: '',
                    borrowTime: '',
                    borrowPurpose: '',
                    borrowUserId: '',
                    borrowCodeNumber: '',
                    current: 1,
                    size: 10,
                },
                userList: [],
                tabsList: [
                    {
                        value: '1',
                        label: '待办'
                    },
                    {
                        value: '0',
                        label: '已办'
                    }
                ],
                loading: false,
                dataList: [],
                total: 0,
                dataListSelections: [],
            }
        },
        mounted() {
            this.getUserList()
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                this.$axios(this.api.record.auditsList, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 我的申请
            mySubmit() {
                this.$router.push({path: '/record/borrow/electronic/submitterList'})
            },
            // 查看
            addEdit(id, index) {
                let searchForm = JSON.parse(JSON.stringify(this.searchForm))
                searchForm.index = (searchForm.current - 1) * searchForm.size + (index + 1)
                this.$refs.auditorForm.init(id, searchForm)
            },
            // 审核记录
            auditLog(id) {
                this.$refs.auditLog.init(id, 0)
            },
            // 审核
            audit(row) {
                if (!row && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = [], val = {}
                if (!row) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                    if(ids.length == 1) {
                        val = this.dataListSelections[0]
                    }
                } else {
                    ids = [row.id]
                    val = row
                }
                this.$refs.auditForm.init(ids, val, 0)
            },
            // 重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 获取用户列表
            getUserList() {
                this.$axios(this.api.user.sysuserQueryAllList, {
                    current: 1,
                    size: 9999,
                    orderBy: '',
                    name: '',
                    loginName: '',
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.userList = data.data.records
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }
    }
</style>
